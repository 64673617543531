/**
 * 二次封装 axios
 * 参考：https://www.jianshu.com/p/6b626d72ea78
 */
import axios from "axios";

// 设置baseURL
// axios.defaults.baseURL = "http://39.99.237.105:6001";

// 设置请求超时
axios.defaults.timeout = 100000;

// post请求头的设置
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 响应拦截器，过滤响应
axios.interceptors.response.use(
  response => {
    if (
      response.status === 200 &&
      response.data &&
      response.data.code === 200
    ) {
      return Promise.resolve(response.data.body);
    } else {
      return Promise.reject(response.data.msg);
    }
  },
  error => {
    console.log(error);
    return Promise.reject(error.message);
  }
);

export default function request(method, url, data = {}) {
  method = method.toLocaleLowerCase();
  if (method === "post") {
    return axios.post(url, data);
  } else if (method === "get") {
    // 如果get请求传入了参数，手动添加一个时间戳，避免304缓存
    data.t = new Date().getTime();
    return axios.get(url, {
      params: data
    });
  } else if (method === "delete") {
    return axios.delete(url, {
      params: data
    });
  }
}
